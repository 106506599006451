import React from 'react';
import { Table } from 'antd';
import dayjs from 'dayjs';
import { useQuery } from 'react-query';
import Filters from '@/components/Filters';
import getColumns from '@/pages/Reports/groupReport/tableColumns';
import { AppStore, FilterStore } from '@/store';
import { seqTimeFormat } from '@/utils/constants';
import report from '@/utils/rest/report';
function GroupReport() {
    var filters = FilterStore.useState();
    var showDays = AppStore.useState(function (s) { return s.showDays; });
    var _a = useQuery('groups', function () { return report.getReport({
        startDate: filters.dates && filters.dates[0] ? filters.dates[0].format(seqTimeFormat) : dayjs().startOf('month').format(seqTimeFormat),
        endDate: filters.dates && filters.dates[1] ? filters.dates[1].format(seqTimeFormat) : dayjs().endOf('day').format(seqTimeFormat),
        reportType: 'groupReport',
    }); }), data = _a.data, isFetching = _a.isFetching, refetch = _a.refetch;
    return (<>
      <Filters refetch={refetch} reportType="groupReport" totalCount={(data === null || data === void 0 ? void 0 : data.items.length) || 0} isExport/>
      <Table rowKey="id" dataSource={data && data.items} loading={isFetching} columns={getColumns(showDays)}/>
    </>);
}
export default GroupReport;
