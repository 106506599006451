export default (function (seconds, toHours) {
    if (toHours === void 0) { toHours = false; }
    if (!seconds) {
        return '00:00:00';
    }
    var days = String(Math.floor(seconds / 86400));
    var hours = toHours
        ? String(Math.floor(seconds / 3600))
        : String(Math.floor((seconds % (24 * 60 * 60)) / (60 * 60)));
    if (Number(hours) < 10)
        hours = "0".concat(hours);
    var minutes = String(Math.floor((seconds % (60 * 60)) / 60));
    if (Number(minutes) < 10)
        minutes = "0".concat(minutes);
    var remainingSeconds = String(Math.round(seconds % 60));
    if (Number(remainingSeconds) < 10)
        remainingSeconds = "0".concat(remainingSeconds);
    if (toHours) {
        return "".concat(hours, ":").concat(minutes, ":").concat(remainingSeconds);
    }
    var dayStr = 'дней';
    var dayCount = Number(days.slice(-1));
    var preLast = Number(days[days.length - 2]);
    if (preLast !== 1) {
        switch (dayCount) {
            case 1:
                dayStr = 'день';
                break;
            case 2:
            case 3:
            case 4:
                dayStr = 'дня';
                break;
            default:
                dayStr = 'дней';
        }
    }
    return Number(days) > 0
        ? "".concat(days, " ").concat(dayStr, ", ").concat(hours, ":").concat(minutes, ":").concat(remainingSeconds)
        : "".concat(hours, ":").concat(minutes, ":").concat(remainingSeconds);
});
