import secToTime from '@/utils/rest/secToTime';
var getColumns = function (showDays) { return [
    {
        title: 'Группировка',
        dataIndex: 'name',
        width: 240,
    },
    {
        title: 'Описание группировки',
        dataIndex: 'description',
        width: 280,
    },
    {
        title: 'Всего обслужено',
        dataIndex: 'ticket_count',
        align: 'center',
    },
    {
        title: 'Общая длительность обслуживания',
        dataIndex: 'total_proc',
        align: 'center',
        render: function (value) { return secToTime(value, showDays); },
    },
    {
        title: 'Общая длительность ожидания',
        dataIndex: 'total_wait',
        align: 'center',
        render: function (value) { return secToTime(value, showDays); },
    },
    {
        title: 'Средняя длительность обслуживания',
        dataIndex: 'avg_proc',
        align: 'center',
        render: function (value) { return secToTime(value, showDays); },
    },
    {
        title: 'Средняя длительность ожидания',
        dataIndex: 'avg_wait',
        align: 'center',
        render: function (value) { return secToTime(value, showDays); },
    },
    {
        title: 'Отлично',
        dataIndex: 'mark5',
        align: 'center',
    },
    {
        title: 'Хорошо',
        dataIndex: 'mark4',
        align: 'center',
    },
    {
        title: 'Нормально',
        dataIndex: 'mark3',
        align: 'center',
    },
    {
        title: 'Плохо',
        dataIndex: 'mark2',
        align: 'center',
    },
    {
        title: 'Оч. плохо',
        dataIndex: 'mark1',
        align: 'center',
    },
    {
        title: 'Средняя оценка',
        dataIndex: 'avg_mark',
        align: 'center',
    },
]; };
export default getColumns;
