var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useState } from 'react';
import { SettingOutlined } from '@ant-design/icons';
import { Button, Checkbox, DatePicker, Drawer, Form, notification, Popconfirm, Select, Skeleton, } from 'antd';
import dayjs from 'dayjs';
import { useMutation, useQuery } from 'react-query';
import { AppStore, FilterStore, InitialFilters } from '@/store';
import { dateTimeFormat, seqTimeFormat } from '@/utils/constants';
import report from '@/utils/rest/report';
var statuses = [
    { key: 3, label: 'Закрыт' },
    { key: 4, label: 'Закрыт по неявке' },
    { key: 9, label: 'Удален системой' },
];
function Filters(_a) {
    var filterItems = _a.filterItems, refetch = _a.refetch, reportType = _a.reportType, totalCount = _a.totalCount, isExport = _a.isExport;
    var _b = useState(false), isDrawerOpen = _b[0], setIsDrawerOpen = _b[1];
    var _c = useState(), currentFilter = _c[0], setCurrentFilter = _c[1];
    var _d = useState(localStorage.getItem('days')), showDays = _d[0], setShowDays = _d[1];
    var filters = FilterStore.useState();
    var _e = useQuery('filters', report.getFilterLists), filterLists = _e.data, isLoading = _e.isLoading;
    var _f = useMutation({
        mutationFn: function (variables) { return report.export(variables.startDate, variables.endDate, variables.reportType, variables.totalCount, variables.filters); },
        onSuccess: function () {
            notification.success({
                message: 'Отчёт подготавливается к скачиванию, скачать отчёт можно в разделе Архив',
            });
        },
        onError: function (error) {
            var _a;
            notification.error({
                message: (_a = error.response) === null || _a === void 0 ? void 0 : _a.data.message,
            });
        },
    }), exportReport = _f.mutate, isExportLoading = _f.isLoading;
    var handleExport = function () {
        if (filters && filters.dates && filters.dates[0] && filters.dates[1]) {
            exportReport({
                startDate: filters.dates[0].format(seqTimeFormat),
                endDate: filters.dates[1].format(seqTimeFormat),
                reportType: reportType,
                totalCount: totalCount,
                filters: filters,
            });
        }
    };
    var changeValue = function (_value, values) {
        setCurrentFilter(values);
    };
    var changeDates = function (dates) {
        if (dates) {
            FilterStore.update(function (s) { s.dates = dates; });
        }
    };
    var formItems = {
        companies: (<Form.Item name="companies" label="По компаниям">
        <Select optionFilterProp="label" mode="multiple">
          {filterLists === null || filterLists === void 0 ? void 0 : filterLists.companies.map(function (company) { return (<Select.Option key={company.id} label={company.name}>{company.name}</Select.Option>); })}
        </Select>
      </Form.Item>),
        offices: (<Form.Item name="offices" label="По офисам">
        <Select optionFilterProp="label" mode="multiple">
          {filterLists === null || filterLists === void 0 ? void 0 : filterLists.offices.map(function (office) {
                var _a, _b;
                if ((currentFilter === null || currentFilter === void 0 ? void 0 : currentFilter.companies) && ((_a = currentFilter.companies) === null || _a === void 0 ? void 0 : _a.length) > 0) {
                    return ((_b = currentFilter.companies) === null || _b === void 0 ? void 0 : _b.includes(String(office.company_id)))
                        ? (<Select.Option key={office.id} label={office.name}>
                    {office.name}
                  </Select.Option>)
                        : null;
                }
                return (<Select.Option key={office.id} label={office.name}>{office.name}</Select.Option>);
            })}
        </Select>
      </Form.Item>),
        wplaces: (<Form.Item name="wplaces" label="По рабочим местам">
        <Select mode="multiple">
          {filterLists === null || filterLists === void 0 ? void 0 : filterLists.wplaces.map(function (wplace) {
                if ((currentFilter === null || currentFilter === void 0 ? void 0 : currentFilter.offices) && currentFilter.offices.length > 0) {
                    var set_1 = new Set(currentFilter.offices);
                    return wplace.offices.some(function (el) { return set_1.has(String(el)); })
                        ? <Select.Option key={wplace.name} value={wplace.name}>{wplace.name}</Select.Option>
                        : null;
                }
                return <Select.Option key={wplace.name} value={wplace.name}>{wplace.name}</Select.Option>;
            })}
        </Select>
      </Form.Item>),
        queues: (<Form.Item name="queues" label="По услугам">
        <Select mode="multiple">
          {filterLists === null || filterLists === void 0 ? void 0 : filterLists.queues.map(function (queue, idx) {
                if ((currentFilter === null || currentFilter === void 0 ? void 0 : currentFilter.offices) && currentFilter.offices.length > 0) {
                    return currentFilter.offices.includes(String(queue.office_id))
                        ? (<Select.Option key={"".concat(queue.id).concat(queue.name).concat(queue.office_id)} value={queue.name}>{queue.name}</Select.Option>)
                        : null;
                }
                return (filterLists.queues.findIndex(function (el) { return el.name === queue.name; }) < idx
                    ? null
                    : (<Select.Option key={"".concat(queue.id).concat(queue.name).concat(queue.office_id)} value={queue.name}>{queue.name}</Select.Option>));
            })}
        </Select>
      </Form.Item>),
        operators: (<Form.Item name="operators" label="По операторам">
        <Select mode="multiple">
          {filterLists === null || filterLists === void 0 ? void 0 : filterLists.operators.map(function (operator, idx) {
                if ((currentFilter === null || currentFilter === void 0 ? void 0 : currentFilter.offices) && currentFilter.offices.length > 0) {
                    return currentFilter.offices.includes(String(operator.office_id))
                        ? (<Select.Option key={"".concat(operator.id).concat(operator.office_id).concat(operator.name)} value={operator.name}>
                    {operator.name}
                  </Select.Option>)
                        : null;
                }
                return (filterLists.operators.findIndex(function (el) { return el.name === operator.name; }) < idx
                    ? null
                    : (<Select.Option key={"".concat(operator.id).concat(operator.office_id).concat(operator.name)} value={operator.name}>
                    {operator.name}
                  </Select.Option>));
            })}
        </Select>
      </Form.Item>),
        status: (<Form.Item name="status" label="По статусу талона">
        <Select>
          {statuses.map(function (el) { return (<Select.Option key={el.key}>{el.label}</Select.Option>); })}
        </Select>
      </Form.Item>),
    };
    var renderForms = function () { return (filterItems === null || filterItems === void 0 ? void 0 : filterItems.map(function (item) { return formItems[item]; })); };
    var toggleDrawer = function () {
        setIsDrawerOpen(function (prevState) { return !prevState; });
    };
    var submitFilters = function (values) {
        FilterStore.update(function (s) { return (__assign(__assign({}, s), values)); });
        toggleDrawer();
    };
    var cancelFilters = function () {
        FilterStore.update(function () { return (__assign({}, InitialFilters)); });
    };
    var setTimeSettings = function () {
        localStorage.setItem('days', String(showDays));
        AppStore.update(function (s) {
            s.showDays = showDays === 'false';
        });
    };
    return (<div className="filter__box">
      <DatePicker.RangePicker format={dateTimeFormat} value={filters.dates} showTime={{
            defaultValue: [
                dayjs().startOf('day'),
                dayjs().endOf('day'),
            ],
        }} onChange={changeDates} style={{ width: '400px' }}/>
      <div className="filter__button__box">
        <div>
          <Button className="filter__button" type="primary" onClick={refetch}>Применить</Button>
          {filterItems && (<>
              <Button className="filter__button" type="primary" onClick={toggleDrawer}>Фильтры</Button>
              <Button className="filter__button" type="primary" onClick={cancelFilters}>Сбросить все фильтры</Button>
            </>)}
        </div>
        {isExport && (<div>
            <Button className="filter__button" type="primary" onClick={handleExport} loading={isExportLoading}>
              Экспортировать
            </Button>
            <Popconfirm title="Настройка отображения времени" icon={null} description={(<Checkbox defaultChecked={showDays !== 'false'} onChange={function (e) {
                    setShowDays(String(e.target.checked));
                }}>
                  Показывать сутки в показателях времени
                </Checkbox>)} onConfirm={setTimeSettings}>
              <SettingOutlined />
            </Popconfirm>
          </div>)}
      </div>
      <Drawer width={600} destroyOnClose open={isDrawerOpen} onClose={toggleDrawer}>
        {isLoading
            ? <Skeleton />
            : (<Form labelCol={{ span: 8 }} wrapperCol={{ span: 16 }} initialValues={filters} onValuesChange={changeValue} onFinish={submitFilters}>
              {renderForms()}
              <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                <Button className="filter__button" htmlType="submit" type="primary">Применить</Button>
              </Form.Item>
            </Form>)}
      </Drawer>
    </div>);
}
export default Filters;
