var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import { CheckCircleTwoTone, CloseCircleTwoTone, RightCircleOutlined, SettingOutlined, VideoCameraOutlined, } from '@ant-design/icons';
import { Checkbox, Divider, Popover, } from 'antd';
import dayjs from 'dayjs';
import { ReactComponent as Mark1 } from '@/assets/icons/gradeIcons/smile_1.svg';
import { ReactComponent as Mark2 } from '@/assets/icons/gradeIcons/smile_2.svg';
import { ReactComponent as Mark3 } from '@/assets/icons/gradeIcons/smile_3.svg';
import { ReactComponent as Mark4 } from '@/assets/icons/gradeIcons/smile_4.svg';
import { ReactComponent as Mark5 } from '@/assets/icons/gradeIcons/smile_5.svg';
import { AppStore } from '@/store';
import { timeDateFormat, timeFormat } from '@/utils/constants';
import secToTime from '@/utils/rest/secToTime';
import getColumnSearchProps from '@/utils/tableSearch';
var getColor = function (timePoints, value) {
    switch (true) {
        case value < timePoints.min:
            return timePoints.colors.min;
        case value < timePoints.max:
            return timePoints.colors.mid;
        default:
            return timePoints.colors.max;
    }
};
var smiles = {
    5: <Mark5 style={{ marginBottom: '-6px' }}/>,
    4: <Mark4 style={{ marginBottom: '-6px' }}/>,
    3: <Mark3 style={{ marginBottom: '-6px' }}/>,
    2: <Mark2 style={{ marginBottom: '-6px' }}/>,
    1: <Mark1 style={{ marginBottom: '-6px' }}/>,
};
var getColumns = function (defaultCols, filteredCols, checkCol, data, setCurrentOffice) {
    var params = AppStore.useState(function (state) { return state.params; });
    return ([
        {
            title: 'Время последней синхронизации',
            dataIndex: 'last_synced',
            fixed: 'left',
            width: 200,
            render: function (_, row) {
                switch (row.sync_status) {
                    case 1:
                        return dayjs(row.last_synced).format(timeDateFormat);
                    case 2:
                        return <p>Синхронизация отключена</p>;
                    default:
                        return (<div>
                <p className="monitoring__table__text">
                  Дата последней синхронизации:
                  <br />
                  {row.last_synced
                                ? dayjs(row.last_synced).format(timeDateFormat)
                                : 'Нет успешных синхронизаций'}
                </p>
                <Divider style={{ margin: '10px 0' }}/>
                <p className="monitoring__table__text">
                  Дата последней попытки синхронизации:
                  <br />
                  {dayjs(row.last_sync).format(timeDateFormat)}
                </p>
              </div>);
                }
            },
        },
        {
            title: 'Название отделения',
            dataIndex: 'office_name',
            fixed: 'left',
            width: 200,
            filters: data === null || data === void 0 ? void 0 : data.map(function (item) { return ({ text: item.office_name, value: item.office_id }); }),
            onFilter: function (value, row) { return value === row.office_id; },
            filterSearch: true,
            sorter: function (a, b) { return ([
                a.office_name.toLowerCase(),
                b.office_name.toLowerCase(),
            ].sort()[0] === a.office_name.toLowerCase() ? -1 : 1); },
        },
        __assign(__assign({ title: 'Адрес', dataIndex: ['Office', 'situation'], width: 280 }, getColumnSearchProps(['Office', 'situation'], 'по адресу офиса')), { sorter: function (a, b) { return ([
                a.Office.situation.toLowerCase(),
                b.Office.situation.toLowerCase(),
            ].sort()[0] === a.Office.situation.toLowerCase() ? -1 : 1); } }),
        {
            title: 'Средняя длительность ожидания',
            dataIndex: 'avg_wait_time',
            align: 'center',
            defaultSortOrder: 'descend',
            render: function (_, row) {
                var _a;
                if (row.sync_status !== 1) {
                    return ' - ';
                }
                var param = (_a = params === null || params === void 0 ? void 0 : params.find(function (el) { return el.param === 'averageWaitTimeMapColorGradation'; })) === null || _a === void 0 ? void 0 : _a.value;
                if (param) {
                    var timePoints = JSON.parse(param);
                    return (<p style={{ color: getColor(timePoints, row.avg_wait_time) }}>
              {dayjs.utc(row.avg_wait_time * 1000).format(timeFormat)}
            </p>);
                }
                return dayjs.utc(row.avg_wait_time * 1000).format(timeFormat);
            },
            sorter: function (a, b) { return a.avg_wait_time - b.avg_wait_time; },
        },
        {
            title: 'Средняя длительность обслуживания',
            dataIndex: 'avg_proc_time',
            align: 'center',
            render: function (_, row) {
                var _a;
                if (row.sync_status !== 1) {
                    return ' - ';
                }
                var param = (_a = params === null || params === void 0 ? void 0 : params.find(function (el) { return el.param === 'averageProcTimeMapColorGradation'; })) === null || _a === void 0 ? void 0 : _a.value;
                if (param) {
                    var timePoints = JSON.parse(param);
                    return (<p style={{ color: getColor(timePoints, row.avg_proc_time) }}>
              {dayjs.utc(row.avg_proc_time * 1000).format(timeFormat)}
            </p>);
                }
                return dayjs.utc(row.avg_proc_time * 1000).format(timeFormat);
            },
            sorter: function (a, b) { return a.avg_proc_time - b.avg_proc_time; },
        },
        {
            title: 'Максимальная длительность ожидания',
            dataIndex: 'max_wait_now',
            align: 'center',
            render: function (value, row) {
                if (row.sync_status !== 1) {
                    return ' - ';
                }
                return secToTime(Number(value));
            },
            sorter: function (a, b) { return Number(a.max_wait_today) - Number(b.max_wait_today); },
        },
        {
            title: 'Максимальная длительность ожидания за день',
            dataIndex: 'max_wait_today',
            align: 'center',
            render: function (value, row) {
                if (row.sync_status !== 1) {
                    return ' - ';
                }
                return secToTime(Number(value));
            },
            sorter: function (a, b) { return Number(a.max_wait_today) - Number(b.max_wait_today); },
        },
        {
            title: 'Средняя оценка',
            dataIndex: 'avg_mark',
            className: 'monitoring__table__column',
            align: 'center',
            render: function (value, row) {
                if (row.sync_status !== 1) {
                    return ' - ';
                }
                return (<Popover title={"\u0421\u0440\u0435\u0434\u043D\u044F\u044F \u043E\u0446\u0435\u043D\u043A\u0430 ".concat(value)} content={(<div className="mark__popover">
                <Mark5 />
                {' '}
                {row.mark5}
                <Mark4 />
                {' '}
                {row.mark4}
                <Mark3 />
                {' '}
                {row.mark3}
                <Mark2 />
                {' '}
                {row.mark2}
                <Mark1 />
                {' '}
                {row.mark1}
              </div>)}>
            {smiles[Math.floor(value)]}
          </Popover>);
            },
            sorter: function (a, b) { return Number(a.avg_mark) - Number(b.avg_mark); },
        },
        {
            title: 'Талоны',
            children: [
                {
                    title: 'Ожидают вызова',
                    dataIndex: 'count_waiting',
                    align: 'center',
                    width: 100,
                    sorter: function (a, b) { return Number(a.count_waiting) - Number(b.count_waiting); },
                    render: function (value, row) {
                        if (row.sync_status !== 1) {
                            return ' - ';
                        }
                        return Number(value);
                    },
                },
                {
                    title: 'В обслу-живании',
                    dataIndex: 'count_in_process',
                    align: 'center',
                    width: 100,
                    sorter: function (a, b) { return Number(a.count_in_process) - Number(b.count_in_process); },
                    render: function (value, row) {
                        if (row.sync_status !== 1) {
                            return ' - ';
                        }
                        return Number(value);
                    },
                },
                {
                    title: 'Закрыто',
                    dataIndex: 'count_closed',
                    align: 'center',
                    width: 100,
                    sorter: function (a, b) { return Number(a.count_closed) - Number(b.count_closed); },
                    render: function (value, row) {
                        if (row.sync_status !== 1) {
                            return ' - ';
                        }
                        return Number(value);
                    },
                },
                {
                    title: 'Неявка',
                    dataIndex: 'count_deleted',
                    align: 'center',
                    width: 100,
                    sorter: function (a, b) { return Number(a.count_deleted) - Number(b.count_deleted); },
                    render: function (value, row) {
                        if (row.sync_status !== 1) {
                            return ' - ';
                        }
                        return Number(value);
                    },
                },
                {
                    title: 'Всего',
                    dataIndex: 'count_all',
                    align: 'center',
                    width: 100,
                    sorter: function (a, b) { return Number(a.count_all) - Number(b.count_all); },
                    render: function (value, row) {
                        if (row.sync_status !== 1) {
                            return ' - ';
                        }
                        return Number(value);
                    },
                },
            ],
        },
        {
            title: 'Рабочие места',
            children: [
                {
                    title: 'Бездей-ствуют',
                    dataIndex: 'wplaces_idle',
                    align: 'center',
                    width: 100,
                    sorter: function (a, b) { return Number(a.wplaces_idle) - Number(b.wplaces_idle); },
                    render: function (value, row) {
                        if (row.sync_status !== 1) {
                            return ' - ';
                        }
                        return Number(value);
                    },
                },
                {
                    title: 'Активно',
                    dataIndex: 'wplaces_busy',
                    align: 'center',
                    width: 100,
                    sorter: function (a, b) { return Number(a.wplaces_busy) - Number(b.wplaces_busy); },
                    render: function (value, row) {
                        if (row.sync_status !== 1) {
                            return ' - ';
                        }
                        return Number(value);
                    },
                },
                {
                    title: 'Всего',
                    dataIndex: 'wplaces_online',
                    align: 'center',
                    width: 100,
                    sorter: function (a, b) { return Number(a.wplaces_online) - Number(b.wplaces_online); },
                    render: function (value, row) {
                        if (row.sync_status !== 1) {
                            return ' - ';
                        }
                        return Number(value);
                    },
                },
            ],
        },
        {
            title: 'Статус синхронизации',
            dataIndex: 'sync_status',
            align: 'center',
            render: function (value) {
                if (value === 2) {
                    return <CloseCircleTwoTone twoToneColor="#FF102D" style={{ fontSize: '24px' }}/>;
                }
                if (value) {
                    return <CheckCircleTwoTone twoToneColor="#18A900" style={{ fontSize: '24px' }}/>;
                }
                return <CloseCircleTwoTone twoToneColor="#FF102D" style={{ fontSize: '24px' }}/>;
            },
        },
        {
            title: 'Камера',
            align: 'center',
            width: 80,
            fixed: 'right',
            render: function (_, row) { return (row.Office.info.cameras
                ? <VideoCameraOutlined style={{ fontSize: '24px' }} onClick={function () { return setCurrentOffice(row); }}/>
                : null); },
        },
        {
            title: (<div className="table__header">
          <p>Перейти</p>
          <Popover title="Настройка столбцов таблицы" trigger="click" content={(<div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                {defaultCols.map(function (col) { return (<Checkbox name={col.title} key={col.title} onChange={checkCol} checked={!filteredCols.includes(col.title)}>
                    {col.title}
                  </Checkbox>); })}
              </div>)}>
            <SettingOutlined className="table__header__icon"/>
          </Popover>
        </div>),
            dataIndex: 'url',
            align: 'center',
            fixed: 'right',
            width: 140,
            render: function (_, row) {
                var url = "".concat(row.Office.url, "?token=").concat(row.Office.token);
                return (<div className="monitoring__url">
            <a href={url} target="_blank" rel="noreferrer">
              <RightCircleOutlined style={{ fontSize: '24px', color: '#05588f' }}/>
            </a>
          </div>);
            },
        },
    ]);
};
export default getColumns;
